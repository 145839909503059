// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();

import '../src/javascript/channels/index.js';

// CSS
import '../src/scss/site'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import $ from "jquery";
import "popper.js";
import "bootstrap";
import toastr from "toastr";
import { toggleJobFields } from '../src/js/toggle_job_fields';
import { addOptionalDocument, deleteOptionalDocument } from '../src/js/optional_documents';
import { addUserDocument } from '../src/js/user_documents';
import { addCheckBoxDeleteNamedDocument } from '../src/js/named_documents';

require('redactor/redactor.min');
require('redactor/_langs/fr');
require('redactor/_langs/en');
require('redactor/_plugins/table/table.min');
require ('../src/js/navbar');
require ('../src/js/input-file');
require ('../src/js/password_strenght_verificator');

toastr.options.toastClass = 'toastr-medium-mtop';

window.toastr = toastr;

$(document).ready(() => {
  $('#funding_application_user_attributes_job_type').on('change', toggleJobFields);
  $('button#add-new-optional-document').on('click', addOptionalDocument);
  $('button#add-new-document').on('click', addUserDocument);
  $('.custom-file-input', '.removable-file').on('change', addCheckBoxDeleteNamedDocument);
  $('form').on('click', '.destroy-optional-document', deleteOptionalDocument);
  toggleJobFields();

  init_redactor();
  $('[data-toggle="tooltip"]').tooltip();

});

function init_redactor() {
  $R(".redactor", {
    buttons: [
      "format",
      "redo",
      "undo",
      "fontcolor",
      "bold",
      "italic",
      "underline",
      "lists",
      "table",
      "image",
      "link",
      "alignment",
      "html"
    ],
    plugins: ['table'],
    lang: "fr",
    formatting: ["p", "h1", "h2", "blockquote"]
  });
}
