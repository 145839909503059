export function toggleJobFields() {
  var jobTypeField = $('#funding_application_user_attributes_job_type');
  var jobFields = $('.job');

  if ($(jobTypeField).val() == 'other') {
    jobFields.map(function() {
      $(jobFields).removeClass('d-none');
    });
  } else {
    jobFields.map(function() {
      $(this).addClass('d-none');
    });
    $('#funding_application_user_attributes_other_job').val('');
  }
}
