export function addCheckBoxDeleteNamedDocument() {
  var parent = $(this).parent();

  $(parent).append('<button class="remove-named-document">Supprimer</button>')

  $('.remove-named-document', parent).on('click', function() {
    var input = $('.custom-file-input', $(this).parent());
    var label = $('.custom-file-label', $(this).parent());

    $(input).val('');
    $(label).text('Fichier');
    $(this).remove();
  })
}
