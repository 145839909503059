export function addOptionalDocument() {
  var newOptionalDocumentCompanyFields = $("#new-optional-document-company-fields").html()
  var idToReplace = parseInt($('#new-optional-document-company-fields :input')[0].id.match(/\d+/)[0])
  var newId = $("input:file").length
  newOptionalDocumentCompanyFields = newOptionalDocumentCompanyFields.replace ( new RegExp(`\\[${idToReplace}\\]`, 'g'),`[${newId}]` )
  $("#fields-optional-documents").append(newOptionalDocumentCompanyFields);
}

export function deleteOptionalDocument() {
  $(this).closest('.optional-document').remove();
}
